import AuthModal from "@/components/auth/auth-modal";
import OfferModal from "@/components/auth/offer-modal";
import { selectHelmet } from "@/store/slices/helmet";
import Head from "next/head";
import Script from "next/script";
import React, { Fragment, PropsWithChildren, useMemo } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Footer from "./footer";
import Header from "./header/Header";
import AccountSelectionModal from "@/components/auth/sccount-selection-modal";
import CustomerConsent from "@/components/auth/customer-consent";
import BillingAddressModal from "@/components/auth/billing-address-modal";

export type LayoutProps = {};

const Layout = ({ children }: PropsWithChildren<LayoutProps>) => {
  const helmet = useSelector(selectHelmet);

  const commonTitle = useMemo(() => {
    if (helmet.ignoreCommonTitle) {
      return "";
    }
    if (helmet.title) {
      return " | " + helmet.commonTitle;
    }
    return "";
  }, [helmet]);

  const pageTitle = useMemo(
    () => `${helmet.title}${commonTitle}`,
    [helmet, commonTitle]
  );

  return (
    <Fragment>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <title>{pageTitle}</title>

        {/* Canonical */}
        {helmet?.path && (
          <>
            <link
              rel="canonical"
              href={`${process.env.NEXT_PUBLIC_APP_URL}${
                helmet.path?.split("?")[0]
              }`}
            />
          </>
        )}

        {/* Primary Meta Tags */}
        {helmet?.meta && (
          <>
            <meta name="title" content={helmet.meta.title || ""} />
            <meta name="description" content={helmet.meta.description || ""} />
            <meta name="keywords" content={helmet.meta.keywords || ""} />
          </>
        )}

        {/* Open Graph / Facebook */}
        {helmet?.og && (
          <>
            <meta property="og:type" content={helmet.og.type || ""} />
            <meta property="og:url" content={helmet.og.url || ""} />
            <meta property="og:title" content={helmet.og.title || ""} />
            <meta
              property="og:description"
              content={helmet.og.description || ""}
            />
            <meta property="og:image" content={helmet.og.image || ""} />
          </>
        )}

        {/* Schema */}
        {!!helmet?.schema && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: helmet.schema,
            }}
          />
        )}
      </Head>
      <Header />
      {children}
      <Footer />
      <AuthModal />
      <BillingAddressModal />
      <OfferModal />
      <AccountSelectionModal />
      <CustomerConsent />
      <ToastContainer
        autoClose={2000}
        theme={"colored"}
        newestOnTop={true}
        position="top-center"
      />
    </Fragment>
  );
};

export default Layout;
